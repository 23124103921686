<template>
	<div>
        <Panel header="Input's" class="p-mb-5">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-lg-2">
                    <label for="InputText">InputText</label>
                    <InputText type="text" placeholder="Default"></InputText>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="InputText">InputText Disabled</label>
                    <InputText type="text" placeholder="Disabled" :disabled="true"></InputText>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="Invalid">Invalid</label>
                    <InputText type="text" placeholder="Invalid" class="p-invalid" />
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="InputText">InputText Icon</label>
                    <span class="p-input-icon-left">
                        <i class="pi pi-user" />
                        <InputText type="text" placeholder="Username" />
                    </span>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="Search">Search Icon</label>
                    <span class="p-input-icon-right">
                        <InputText type="text" placeholder="Search" />
                        <i class="pi pi-search" />
                    </span>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="Search">Search</label>
                    <span class="p-input-icon-left p-input-icon-right">
                        <i class="pi pi-user" />
                        <InputText type="text" placeholder="Search" />
                        <i class="pi pi-search" />
                    </span>
                </div>

                <div class="p-field p-col-6 p-lg-2">
                    <label for="Calendar">Calendar</label>
                    <Calendar :showIcon="true" :showButtonBar="true" v-model="calendarValue" dateFormat="dd/mm/yy"></Calendar>
                </div>

                <div class="p-field p-col-6 p-lg-2">
                    <label for="InputNumber">Inpu tNumber</label>
                    <InputNumber v-model="inputNumberValue" showButtons mode="decimal"></InputNumber>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="Chips">Chips</label>
                    <Chips v-model="chipsValue"/>
                </div>

                <div class="p-field p-col-12 p-lg-2 p-mt-4">
                    <span class="p-float-label">
                        <InputText id="username" type="text" v-model="floatValue"/>
                        <label for="username">Float Label</label>
                    </span>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="Textarea">Textarea</label>
                    <Textarea placeholder="Your Message" :autoResize="true" rows="1" cols="30" />
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="AutoComplete">AutoComplete</label>
                    <AutoComplete placeholder="Search" id="dd" :dropdown="true" :multiple="true" v-model="selectedAutoValue" :suggestions="autoFilteredValue" @complete="searchCountry($event)" field="name"/>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="Slider">Slider</label>
                    <InputText v-model.number="sliderValue" />
                    <Slider v-model="sliderValue" />
                </div>
                
                <div class="p-field p-col-12 p-lg-2">
                    <label for="Dropdown">Dropdown</label>
                    <Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Select" />
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="InputText">Dropdown Icon</label>
                    <span class="p-input-icon-left">
                        <Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Seleção com ícone" class="p-pl-4" />
                        <i class="pi pi-user" />
                    </span>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="MultiSelect">MultiSelect</label>
                    <MultiSelect v-model="multiselectValue" :options="multiselectValues" optionLabel="name" placeholder="Select Countries" :filter="true"></MultiSelect>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="option" class="p-mb-3">RadioButton</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="option1" name="option" value="Option 1" v-model="radioValue" />
                        <label for="option1">Option 1</label>
                    </div>
                    <div class="p-field-radiobutton">
                        <RadioButton id="option2" name="option" value="Option 2" v-model="radioValue" />
                        <label for="option2">Option 2</label>
                    </div>
                    <div class="p-field-radiobutton">
                        <RadioButton id="option3" name="option" value="Option 3" v-model="radioValue" />
                        <label for="option3">Option 3</label>
                    </div>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="checkbox" class="p-mb-3">Checkbox</label>
                    <div class="p-field-checkbox">
                        <Checkbox id="checkOption1" name="option" value="Option 1" v-model="checkboxValue" />
                        <label for="checkOption1">Option 1</label>
                    </div>
                    <div class="p-field-checkbox">
                        <Checkbox id="checkOption2" name="option" value="Option 2" v-model="checkboxValue" />
                        <label for="checkOption2">Option 2</label>
                    </div>
                    <div class="p-field-checkbox">
                        <Checkbox id="checkOption3" name="option" value="Option 3" v-model="checkboxValue" />
                        <label for="checkOption3">Option 3</label>
                    </div>
                </div>
                
                <div class="p-field p-col-12 p-lg-1">
                    <label for="InputSwitch" class="p-d-block p-mb-3">Input Switch</label>
                    <InputSwitch v-model="switchValue" />
                </div>
            
                <div class="p-field p-col-12 p-lg-2">
                    <label for="Rating" class="p-d-block p-mb-3">Rating</label>
                    <Rating v-model="ratingValue"/>
                </div>
                
                <div class="p-field p-col-12 p-lg-2">
                    <label for="username2">Username</label>
                    <InputText id="username2" type="text" aria-describedby="username-help" />
                    <small id="username-help" class="p-error">Enter your username to reset your password.</small>
                </div>

                <div class="p-field p-col-12 p-lg-2">
                    <label for="Listbox">Listbox</label>
                    <Listbox v-model="listboxValue" :options="listboxValues" optionLabel="name" :filter="true" />
                </div>
            </div>
        </Panel>

        <Panel header="Button / Badges / Toggle Butons" class="p-mb-5">
            <div class="p-grid">
                <div class="p-col-12 p-md-1">
                    <h5>ToggleButton</h5>
                    <ToggleButton v-model="toggleValue" onLabel="Yes" offLabel="No"/>
                </div>
                <div class="p-col-12 p-md-3">
                    <h5>SelectButton</h5>
                    <SelectButton v-model="selectButtonValue1" :options="selectButtonValues1" optionLabel="name" />
                </div>
                <div class="p-col-12 p-md-3">
                    <h5>SelectButton - Multiple</h5>
                    <SelectButton v-model="selectButtonValue2" :options="selectButtonValues2" optionLabel="name" :multiple="true"/>
                </div>
            </div>
        
            <div class="p-grid">
                <div class="p-md-12 p-lg-6">
                    <h5>Buttons</h5>
                    <Button label="Submit" class="p-mr-2 p-mb-2" />
                    <Button icon="pi pi-check" class="p-mr-2 p-mb-2" />
                    <Button label="Submit" icon="pi pi-check" class="p-mr-2 p-mb-2" />
                    <Button label="Submit" icon="pi pi-check" iconPos="right" class="p-mr-2 p-mb-2" />
                    <Button label="Disabled" disabled="disabled" class="p-mr-2 p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Severities</h5>
                    <Button label="Primary" class="p-mr-2 p-mb-2" />
                    <Button label="Secondary" class="p-button-secondary p-mr-2 p-mb-2" />
                    <Button label="Success" class="p-button-success p-mr-2 p-mb-2" />
                    <Button label="Info" class="p-button-info p-mr-2 p-mb-2" />
                    <Button label="Warning" class="p-button-warning p-mr-2 p-mb-2" />
                    <Button label="Help" class="p-button-help p-mr-2 p-mb-2" />
                    <Button label="Danger" class="p-button-danger p-mr-2 p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Raised Buttons</h5>
                    <Button label="Primary" class="p-button-raised p-mr-2 p-mb-2" />
                    <Button label="Secondary" class="p-button-raised p-button-secondary p-mr-2 p-mb-2" />
                    <Button label="Success" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button label="Info" class="p-button-raised p-button-info p-mr-2 p-mb-2" />
                    <Button label="Warning" class="p-button-raised p-button-warning p-mr-2 p-mb-2" />
                    <Button label="Help" class="p-button-raised p-button-help p-mr-2 p-mb-2" />
                    <Button label="Danger" class="p-button-raised p-button-danger p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Rounded Buttons</h5>
                    <Button label="Primary" class="p-button-rounded p-mr-2 p-mb-2" />
                    <Button label="Secondary" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" />
                    <Button label="Success" class="p-button-rounded p-button-success p-mr-2 p-mb-2" />
                    <Button label="Info" class="p-button-rounded p-button-info p-mr-2 p-mb-2" />
                    <Button label="Warning" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" />
                    <Button label="Help" class="p-button-rounded p-button-help p-mr-2 p-mb-2" />
                    <Button label="Danger" class="p-button-rounded p-button-danger p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Text Buttons</h5>
                    <Button label="Primary" class="p-button-text p-mr-2 p-mb-2" />
                    <Button label="Secondary" class="p-button-secondary p-button-text p-mr-2 p-mb-2" />
                    <Button label="Success" class="p-button-success p-button-text p-mr-2 p-mb-2" />
                    <Button label="Info" class="p-button-info p-button-text p-mr-2 p-mb-2" />
                    <Button label="Warning" class="p-button-warning p-button-text p-mr-2 p-mb-2" />
                    <Button label="Help" class="p-button-help p-button-text p-mr-2 p-mb-2" />
                    <Button label="Danger" class="p-button-danger p-button-text p-mr-2 p-mb-2" />
                    <Button label="Plain" class="p-button-text p-button-plain p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Raised Text Buttons</h5>
                    <Button label="Primary" class="p-button-raised p-button-text p-mr-2 p-mb-2" />
                    <Button label="Secondary" class="p-button-raised p-button-secondary p-button-text p-mr-2 p-mb-2" />
                    <Button label="Success" class="p-button-raised p-button-success p-button-text p-mr-2 p-mb-2" />
                    <Button label="Info" class="p-button-raised p-button-info p-button-text p-mr-2 p-mb-2" />
                    <Button label="Warning" class="p-button-raised p-button-warning p-button-text p-mr-2 p-mb-2" />
                    <Button label="Help" class="p-button-raised p-button-help p-button-text p-mr-2 p-mb-2" />
                    <Button label="Danger" class="p-button-raised p-button-danger p-button-text p-mr-2 p-mb-2" />
                    <Button label="Plain" class="p-button-raised p-button-plain p-button-text p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Outlined Buttons</h5>
                    <Button label="Primary" class="p-button-outlined p-mr-2 p-mb-2" />
                    <Button label="Secondary" class="p-button-outlined p-button-secondary p-mr-2 p-mb-2" />
                    <Button label="Success" class="p-button-outlined p-button-success p-mr-2 p-mb-2" />
                    <Button label="Info" class="p-button-outlined p-button-info p-mr-2 p-mb-2" />
                    <Button label="Warning" class="p-button-outlined p-button-warning p-mr-2 p-mb-2" />
                    <Button label="Help" class="p-button-outlined p-button-help p-mr-2 p-mb-2" />
                    <Button label="Danger" class="p-button-outlined p-button-danger p-mr-2 p-mb-2" />
                    <Button label="Plain" class="p-button-outlined p-button-plain p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Rounded Icon Buttons</h5>
                    <Button icon="pi pi-check" class="p-button-rounded p-mr-2 p-mb-2" />
                    <Button icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" />
                    <Button icon="pi pi-search" class="p-button-rounded p-button-success p-mr-2 p-mb-2" />
                    <Button icon="pi pi-user" class="p-button-rounded p-button-info p-mr-2 p-mb-2" />
                    <Button icon="pi pi-bell" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" />
                    <Button icon="pi pi-heart" class="p-button-rounded p-button-help p-mr-2 p-mb-2" />
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Rounded Text Icon Buttons</h5>
                    <Button icon="pi pi-check" class="p-button-rounded p-button-text p-mr-2 p-mb-2" />
                    <Button icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2" />
                    <Button icon="pi pi-search" class="p-button-rounded p-button-success p-button-text p-mr-2 p-mb-2" />
                    <Button icon="pi pi-user" class="p-button-rounded p-button-info p-button-text p-mr-2 p-mb-2" />
                    <Button icon="pi pi-bell" class="p-button-rounded p-button-warning p-button-text p-mr-2 p-mb-2" />
                    <Button icon="pi pi-heart" class="p-button-rounded p-button-help p-button-text p-mr-2 p-mb-2" />
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Rounded and Outlined Icon Buttons</h5>
                    <Button icon="pi pi-check" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2" />
                    <Button icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-button-outlined p-mr-2 p-mb-2" />
                    <Button icon="pi pi-search" class="p-button-rounded p-button-success p-button-outlined p-mr-2 p-mb-2" />
                    <Button icon="pi pi-user" class="p-button-rounded p-button-info p-button-outlined p-mr-2 p-mb-2" />
                    <Button icon="pi pi-bell" class="p-button-rounded p-button-warning p-button-outlined p-mr-2 p-mb-2" />
                    <Button icon="pi pi-heart" class="p-button-rounded p-button-help p-button-outlined p-mr-2 p-mb-2" />
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined p-mb-2" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Badges</h5>
                    <Button type="button" label="Emails" badge="8" class="p-mr-2 p-mb-2" />
                    <Button type="button" label="Messages" icon="pi pi-users" class="p-button-warning p-mb-2" badge="8" badgeClass="p-badge-danger" />
                </div>
                <div class="p-md-12 p-lg-6">
                    <h5>Button Set</h5>
                    <span class="p-buttonset">
                        <Button label="Save" icon="pi pi-check" />
                        <Button label="Delete" icon="pi pi-trash" />
                        <Button label="Cancel" icon="pi pi-times" />
                    </span>
                </div>
            </div>
        </Panel>

        <Panel header="Table" class="p-mb-5">
            <div class="p-d-flex p-jc-between p-ai-center p-mb-5">
                <h5 class="p-m-0">Descrição padrão de telas</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                </span>
            </div>
            <DataTable :value="customers" :paginator="true" :rows="10"
                dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" v-model:filters="filters" filterDisplay="menu"
                paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink" :rowsPerPageOptions="[10,25,50]"
                currentPageReportTemplate="Exibindo {first} - {last} de {totalRecords} Registro(s)"
                :globalFilterFields="['name','country.name','representative.name','status']" responsiveLayout="stack"  breakpoint="960px">
                <!--template #header>
                        <div class="p-d-flex p-jc-between p-ai-center">
                        <h5 class="p-m-0">Customers</h5>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
                        </div>
                </template-->
                <template #empty>
                    No customers found.
                </template>
                <Column selectionMode="multiple" style="width: 1rem;"></Column>
                <Column field="name" header="Nome" sortable style="min-width: 14rem">
                    <template #body="{data}">
                        {{data.name}}
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar por"/>
                    </template>
                </Column>
                <Column field="country.name" header="País" sortable filterMatchMode="contains" style="min-width: 14rem">
                    <template #body="{data}">
                        <img src="../assets/images/flag_placeholder.png" :class="'flag flag-' + data.country.code" width="30" />
                        <span class="image-text p-ml-2">{{data.country.name}}</span>
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by country"/>
                    </template>
                </Column>
                <Column header="Usuário" sortable filterField="representative" sortField="representative.name" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
                        <template #body="{data}">
                        <img :alt="data.representative.name" :src="'demo/images/avatar/' + data.representative.image" width="32" style="vertical-align: middle" />
                        <span class="image-text p-ml-2">{{data.representative.name}}</span>
                    </template>
                    <template #filter="{filterModel}">
                        <div class="p-mb-3 p-text-bold">Agent Picker</div>
                        <MultiSelect v-model="filterModel.value" :options="representatives" optionLabel="name" placeholder="Any" class="p-column-filter">
                            <template #option="slotProps">
                                <div class="p-multiselect-representative-option">
                                    <img :alt="slotProps.option.name" :src="'demo/images/avatar/' + slotProps.option.image" width="32" style="vertical-align: middle" />
                                    <span class="image-text p-ml-2">{{slotProps.option.name}}</span>
                                </div>
                            </template>
                        </MultiSelect>
                    </template>
                </Column>
                <Column field="date" header="Data" sortable dataType="date" style="min-width: 8rem">
                    <template #body="{data}">
                        {{formatDate(data.date)}}
                    </template>
                    <template #filter="{filterModel}">
                        <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                    </template>
                </Column>
                <Column field="balance" header="Valor" sortable dataType="numeric" style="min-width: 8rem; text-align: right">
                    <template #body="{data}">
                        {{formatCurrency(data.balance)}}
                    </template>
                    <template #filter="{filterModel}">
                        <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pr-BR" />
                    </template>
                </Column>
                <Column field="status" header="Status" sortable :filterMenuStyle="{'width':'14rem'}" style="min-width: 10rem">
                    <template #body="{data}">
                        <span :class="'status status-' + data.status">{{data.status}}</span>
                    </template>
                    <template #filter="{filterModel}">
                        <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                            <template #value="slotProps">
                                <span :class="'status status-' + slotProps.value">{{slotProps.value}}</span>
                            </template>
                            <template #option="slotProps">
                                <span :class="'status status-' + slotProps.option">{{slotProps.option}}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column field="activity" header="Porcentagem" sortable :showFilterMatchModes="false" style="min-width: 10rem">
                    <template #body="{data}">
                        <ProgressBar :value="data.activity" :showValue="false" />
                    </template>
                    <template #filter="{filterModel}">
                        <Slider v-model="filterModel.value" range class="p-m-3"></Slider>
                        <div class="p-d-flex p-ai-center p-jc-between p-px-2">
                            <span>{{filterModel.value ? filterModel.value[0] : 0}}</span>
                            <span>{{filterModel.value ? filterModel.value[1] : 100}}</span>
                        </div>
                    </template>
                </Column>
                <Column headerStyle="width: 4.5rem; text-align: center">
                    <template #body>
                        <Button type="button" icon="pi pi-cog"></Button>
                    </template>
                </Column>
            </DataTable>


            <h3 class="p-mt-5">Secondary Table <small>(.datatable-secondary)</small></h3>
            <DataTable :value="customers" :paginator="true" :rows="10"
                dataKey="id" :rowHover="true" 
                paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink" :rowsPerPageOptions="[10,25,50]"
                currentPageReportTemplate="Exibindo {first} - {last} de {totalRecords} Registro(s)"
                :globalFilterFields="['name','country.name','representative.name','status']" responsiveLayout="stack"  breakpoint="960px" class="datatable-secondary">
                <template #empty>
                    No customers found.
                </template>
                <Column field="name" header="Nome" sortable style="min-width: 14rem">
                    <template #body="{data}">
                        {{data.name}}
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar por"/>
                    </template>
                </Column>
                <Column field="country.name" header="País" sortable filterMatchMode="contains" style="min-width: 14rem">
                    <template #body="{data}">
                        <img src="../assets/images/flag_placeholder.png" :class="'flag flag-' + data.country.code" width="30" />
                        <span class="image-text p-ml-2">{{data.country.name}}</span>
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by country"/>
                    </template>
                </Column>
                <Column header="Usuário" sortable filterField="representative" sortField="representative.name" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
                        <template #body="{data}">
                        <img :alt="data.representative.name" :src="'demo/images/avatar/' + data.representative.image" width="32" style="vertical-align: middle" />
                        <span class="image-text p-ml-2">{{data.representative.name}}</span>
                    </template>
                    <template #filter="{filterModel}">
                        <div class="p-mb-3 p-text-bold">Agent Picker</div>
                        <MultiSelect v-model="filterModel.value" :options="representatives" optionLabel="name" placeholder="Any" class="p-column-filter">
                            <template #option="slotProps">
                                <div class="p-multiselect-representative-option">
                                    <img :alt="slotProps.option.name" :src="'demo/images/avatar/' + slotProps.option.image" width="32" style="vertical-align: middle" />
                                    <span class="image-text p-ml-2">{{slotProps.option.name}}</span>
                                </div>
                            </template>
                        </MultiSelect>
                    </template>
                </Column>
                <Column field="date" header="Data" sortable dataType="date" style="min-width: 8rem">
                    <template #body="{data}">
                        {{formatDate(data.date)}}
                    </template>
                    <template #filter="{filterModel}">
                        <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                    </template>
                </Column>
                <Column field="balance" header="Valor" sortable dataType="numeric" style="min-width: 8rem; text-align: right">
                    <template #body="{data}">
                        {{formatCurrency(data.balance)}}
                    </template>
                    <template #filter="{filterModel}">
                        <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pr-BR" />
                    </template>
                </Column>
                <Column field="status" header="Status" sortable :filterMenuStyle="{'width':'14rem'}" style="min-width: 10rem">
                    <template #body="{data}">
                        <span :class="'status status-' + data.status">{{data.status}}</span>
                    </template>
                    <template #filter="{filterModel}">
                        <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                            <template #value="slotProps">
                                <span :class="'status status-' + slotProps.value">{{slotProps.value}}</span>
                            </template>
                            <template #option="slotProps">
                                <span :class="'status status-' + slotProps.option">{{slotProps.option}}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column field="activity" header="Porcentagem" sortable :showFilterMatchModes="false" style="min-width: 10rem">
                    <template #body="{data}">
                        <ProgressBar :value="data.activity" :showValue="false" />
                    </template>
                    <template #filter="{filterModel}">
                        <Slider v-model="filterModel.value" range class="p-m-3"></Slider>
                        <div class="p-d-flex p-ai-center p-jc-between p-px-2">
                            <span>{{filterModel.value ? filterModel.value[0] : 0}}</span>
                            <span>{{filterModel.value ? filterModel.value[1] : 100}}</span>
                        </div>
                    </template>
                </Column>
                <Column headerStyle="width: 4.5rem; text-align: center">
                    <template #body>
                        <Button type="button" icon="pi pi-cog"></Button>
                    </template>
                </Column>
            </DataTable>
        </Panel>

        <Panel header="AccordionPanel" class="p-mb-5">
            <h3>Default</h3>
            <Accordion :multiple="true" :activeIndex="[0]">
                <AccordionTab header="Header I">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </AccordionTab>
                <AccordionTab header="Header II">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
                </AccordionTab>
                <AccordionTab header="Header III">
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
                </AccordionTab>
            </Accordion>

            <h3 class="p-mt-5">Secondary <small>(.p-accordion-secondary)</small></h3>
            <Accordion :multiple="true" :activeIndex="[0]" class="p-accordion-secondary">
                <AccordionTab header="Header I">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </AccordionTab>
                <AccordionTab header="Header II">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
                </AccordionTab>
                <AccordionTab header="Header III">
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
                </AccordionTab>
            </Accordion>

            <h3 class="p-mt-5">Terciary <small>(.p-accordion-tertiary)</small></h3>
            <Accordion :multiple="true" :activeIndex="[0]" class="p-accordion-tertiary">
                <AccordionTab header="Header I">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </AccordionTab>
                <AccordionTab header="Header II">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
                </AccordionTab>
                <AccordionTab header="Header III">
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
                </AccordionTab>
            </Accordion>
        </Panel>

        <Panel header="TabView" class="p-mb-5">
            <h3>Default</h3>
            <TabView>
                <TabPanel header="Header I">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p></TabPanel>
                <TabPanel header="Header II">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
                </TabPanel>
                <TabPanel header="Header III">
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
                </TabPanel>
            </TabView>

            <h3 class="p-mt-5">Secondary</h3>
            <TabView class="p-tabview-secondary">
                <TabPanel header="Header I">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p></TabPanel>
                <TabPanel header="Header II">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
                </TabPanel>
                <TabPanel header="Header III">
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
                </TabPanel>
            </TabView>
        </Panel>

        <Panel header="Panel toggleable" class="p-mb-5" :toggleable="true">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </Panel>

        <Panel header="Fieldset" class="p-mb-5">
            <div class="p-grid">
                <div class="p-col-12 p-md-6 p-my-2">
                    <Fieldset legend="Fieldset">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </Fieldset>
                </div>
                <div class="p-col-12 p-md-6 p-my-2">
                    <Fieldset legend="Fieldset toggleable" :toggleable="true">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </Fieldset>
                </div>
            </div>
        </Panel>

        <Panel header="Overlay / Overlay Menu / Dialog / ContextMenu" class="p-mb-5">
            <div class="p-grid">
                <div class="p-col-12 p-md-1">
                    <h5>Overlay Panel</h5>
                    <Button type="button" label="Choose" @click="toggle" icon="pi pi-search" />
                    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" style="width: 450px">
                        <DataTable :value="products" v-model:selection="selectedProduct" selectionMode="single" :paginator="true" :rows="5" @row-select="onProductSelect">
                            <Column field="name" header="Name" sortable></Column>
                            <Column header="Image">
                                <template #body="slotProps">
                                    <img :src="'demo/images/product/' + slotProps.data.image" :alt="slotProps.data.image" width="50" />
                                </template>
                            </Column>
                            <Column field="price" header="Price" sortable>
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.price)}}
                                </template>
                            </Column>
                        </DataTable>
                    </OverlayPanel>
                </div>

                <div class="p-col-12 p-md-1">
                    <h5>Dialog</h5>
                    <Dialog header="Dialog" v-model:visible="display" :modal="true" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <template #footer>
                            <Button label="Dismiss" @click="close" icon="pi pi-check" class="p-button-text" />
                        </template>
                    </Dialog>
                    <Button label="Show" icon="pi pi-external-link" @click="open" class="p-button-secondary" />
                </div>

                <div class="p-col-12 p-md-1">
                    <h5>Confirm Popup</h5>
                    <Button type="button" label="Confirm" @click="confirmWithPopup" class="p-button-success" icon="pi pi-check" />
                    <ConfirmPopup group="popup"></ConfirmPopup>
                </div>

                <div class="p-col-12 p-md-2">
                    <h5>Confirm Dialog</h5>
                    <Button type="button" label="Confirm" @click="confirmWithDialog" class="p-button-help" icon="pi pi-check" />
                    <Button type="button" label="Delete" @click="deletmWithDialog" class="p-button-danger p-ml-3" icon="pi pi-times" />
                    <ConfirmDialog group="dialog">></ConfirmDialog>
                </div>

                <div class="p-col-12 p-md-2">
                    <h5>Menu Dialog</h5>
                    <Menu ref="menu" :model="menuitems" :popup="true" />
                    <Button type="button" label="Options" icon="pi pi-angle-down" @click="toggleMenu" style="width: auto"/>
                </div>

                <div class="p-col-12 p-md-4">
                    <h5>ContextMenu</h5>
                    <div header="ContextMenu" @contextmenu="onContextRightClick" class="p-mb-5">
                        Clique com o botão direito para simular o menu.
                        <ContextMenu ref="contextMenu" :model="contextMenuItems" />
                    </div>
                </div>
            </div>
        </Panel>

        <Panel header="Menubar" class="p-mb-5">
            <div class="p-grid">
                <div class="p-col-12">
                    <Menubar :model="tieredMenuItems">
                        <template #end>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText type="text" placeholder="Search" />
                            </span>
                        </template>
                    </Menubar>
                </div>
                <div class="p-col-12 p-md-6">
                    <TieredMenu :model="tieredMenuItems" />
                </div>
                <div class="p-col-12 p-md-6">
                    <Menu :model="menuitems" />
                </div>
            </div>
        </Panel>

        <Panel header="Messages & Tag" class="p-mb-5">
            <div class="p-grid">
                <div class="p-col-12 p-md-4">
                    <h3>Messages</h3>
                    <Message severity="info">Info Message</Message>
                    <Message severity="success">Success Message</Message>
                    <Message severity="warn">Warning Message</Message>
                    <Message severity="error">Error Message</Message>
                </div>

                <div class="p-col-12 p-md-4">
                    <h3>Tag's</h3>
                    <Tag value="Sucesso / Finalizado / Analisado / Validado / Classificado / Aprovado" severity="" class="p-mr-3 p-mb-3"></Tag>
                    <Tag value="Processando / Aguardando / Pendente / Salvando" severity="info" class="p-mr-3 p-mb-3"></Tag>
                    <Tag value="Processando / Aguardando / Pendente / Salvando" severity="warning" class="p-mr-3 p-mb-3"></Tag>
                    <Tag value="Erro / Cancelamento / Recusa / Vencido / Negação" severity="danger" class="p-mr-3 p-mb-3"></Tag>
                    <Tag value="Success With Ícon" severity="success" icon="pi pi-plus"></Tag>
                </div>

                <div class="p-col-12 p-md-4">
                    <h3>Alert's (Converter para componente)</h3>
                    <div role="alert" class="status status-success p-mb-3">Sucesso / Finalizado / Analisado / Validado / Classificado / Aprovado</div>
                    <div role="alert" class="status status-info p-mb-3">Processando / Aguardando / Pendente / Salvando</div>
                    <div role="alert" class="status status-warning p-mb-3">Arquivo retornado para esta etapa </div>
                    <div role="alert" class="status status-danger p-mb-3">Erro / Cancelamento / Recusa / Vencido / Negação</div>
                    <div role="alert" class="status status-human p-mb-3">Iteração Humana</div>
                    <div role="alert" class="status status-desabled p-mb-3">Retorno / Inatividade / Transição</div>
                </div>
            </div>
        </Panel>

        <Panel header="Toast" class="p-mb-5">
            <Toast position="top-center" />
            <Button @click="showToast('info')" label="Info" class="p-button-info p-mr-2 p-mb-2" />
            <Button @click="showToast('success')" label="Success" class="p-button-success p-mr-2 p-mb-2" />
            <Button @click="showToast('warn')" label="Warn" class="p-button-warning p-mr-2 p-mb-2" />
            <Button @click="showToast('error')" label="Error" class="p-button-danger p-mb-2" />
        </Panel>

        <Panel header="Timeline" class="p-mb-5">
            <div class="p-grid">
                <div class="p-col-12 p-md-3">
                    <Timeline :value="timelineEvents" align="right">
                        <template #content="slotProps">
                            {{slotProps.item.status}}
                        </template>
                    </Timeline>
                </div>
                <div class="p-col-12 p-md-3">
                    <Timeline :value="timelineEvents" align="right">
                        <template #content="slotProps">
                            {{slotProps.item.status}}<br>
                            <small class="p-text-secondary">{{slotProps.item.date}}</small>
                        </template>
                    </Timeline>
                </div>
                <div class="p-col-12 p-md-3">
                    <Timeline :value="timelineEvents" align="alternate">
                        <template #content="slotProps">
                            {{slotProps.item.status}}
                        </template>
                    </Timeline>
                </div>
                <div class="p-col-12 p-md-3">
                    <Timeline :value="timelineEvents">
                        <template #opposite="slotProps">
                            <small class="p-text-secondary">{{slotProps.item.date}}</small>
                        </template>
                        <template #content="slotProps">
                            {{slotProps.item.status}}
                        </template>
                    </Timeline>
                </div>
            </div>

            <div class="p-grid p-mt-5">
                <div class="p-col-12">
                    <h3>Customizado <small>(.customized-timeline)</small></h3>
                    <Timeline :value="timelineEvents" align="right" class="customized-timeline">
                        <template #content="slotProps">
                            <Card :class="slotProps.item.animate">
                                <template #content>
                                    <span><b>{{slotProps.item.date}}</b></span>
                                    <span>{{slotProps.item.time}}</span>
                                </template>
                            </Card>
                        </template>
                        <template #marker="slotProps">
                            <span class="custom-marker p-shadow-2">
                                <i :class="slotProps.item.icon" :style="{backgroundColor: slotProps.item.color}"></i>
                            </span>
                        </template>
                        <template #opposite="slotProps">
                            <Card>
                                <!--template #title>
                                    {{slotProps.item.status}}
                                </templateW-->
                                <template #content>
                                    <!--img v-if="slotProps.item.image" src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" :alt="slotProps.item.name" width="200" class="p-shadow-2" /-->
                                    {{slotProps.item.description}}
                                </template>
                            </Card>
                        </template>
                    </Timeline>
                </div>
            </div>
        </Panel>

        <Panel header="Tooltip" class="p-mb-5">
            <div class="p-grid">
                <div class="p-col-12 p-md-2 p-text-center">
                    <a v-tooltip.right="'Enter your username'">Tooltip Right</a>
                </div>
                <div class="p-col-12 p-md-2 p-text-center">
                    <a v-tooltip.top="'<ul class=tooltip-list><li><span>Título do Tooltip</span><div>As variáveis de consequência selecionadas serão atribuídas</div></li><li><span>Título</span><div>354195</div></li><li><span>Título</span><div>R$ 250,00</div></li></ul>'">Tooltip Top - Lista</a>
                </div>
                <div class="p-col-12 p-md-2 p-text-center">
                    <a v-tooltip.top="'As variáveis de consequência selecionadas serão atribuídas e sugeridas tão somente aos estabelecimentos vinculados a esse Cliente do filtro ativo e não aos demais estabelecimentos no ROIT BANK.'" class="p-mx-5 tooltip-large">Tooltip Top - Texto</a>
                </div>
                <div class="p-col-12 p-md-2 p-text-center">
                    <a v-tooltip.bottom="'Enter your username'">Tooltip Bottom</a>
                </div>
                <div class="p-col-12 p-md-2 p-text-center">
                    <a v-tooltip.left="'Enter your username'">Tooltip Left</a>
                </div>
            </div>
        </Panel>

        <Panel header="Stepper" class="p-mb-5">
            <Steps ref="Stepper" :model="items" :readonly="false" />
            <router-view v-slot="{Component}" :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </Panel>
        
        <Panel header="Sidebar" class="p-mb-5">
            <Button icon="pi pi-arrow-right" @click="visibleLeft = true" class="p-mr-2" />
            <Button icon="pi pi-arrow-left" @click="visibleRight = true" class="p-mr-2"  />
            <Button icon="pi pi-arrow-down" @click="visibleTop = true" class="p-mr-2"  />
            <Button icon="pi pi-arrow-up" @click="visibleBottom = true" class="p-mr-2"  />
            <Button icon="pi pi-th-large" @click="visibleFull = true"  />

            <Sidebar v-model:visible="visibleLeft" :baseZIndex="1000" class="p-sidebar-md">
                <h3>Left Sidebar</h3>
            </Sidebar>

            <Sidebar v-model:visible="visibleRight" :baseZIndex="1000" position="right">
                <h3>Right Sidebar</h3>
            </Sidebar>

            <Sidebar v-model:visible="visibleTop" :baseZIndex="1000" position="top">
                <h3>Top Sidebar</h3>
            </Sidebar>

            <Sidebar v-model:visible="visibleBottom" :baseZIndex="1000" position="bottom">
                <h3>Bottom Sidebar</h3>
            </Sidebar>

            <Sidebar v-model:visible="visibleFull" :baseZIndex="1000" position="full">
                <h3>Full Screen</h3>
            </Sidebar>
        </Panel>

        <Panel header="Custom Components" class="p-mb-5">
            <h3>Default</h3>
            <SingleFile />

            <h3>Secondary</h3>
            <SingleFile class="custom-single-file-secondary" />
        </Panel>
    </div>
</template>

<script>
import CustomerService from '../service/CustomerService';
import CountryService from '../service/CountryService';
import ProductService from '../service/ProductService';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
    
export default {
    data() {
        return {
            customers: null,
            selectedCustomers: null,
            products: null,
            selectedProduct: null,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'representative': {value: null, matchMode: FilterMatchMode.IN},
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
                'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
            },
            floatValue: null,
            autoValue: null,
            selectedAutoValue: null,
            autoFilteredValue: [],
            calendarValue: null,
            inputNumberValue: null,
            chipsValue: null,
            sliderValue: null,
            ratingValue: null,
            colorValue: '1976D2',
            radioValue: null,
            checkboxValue: [],
            switchValue: false,
            display: false,
            listboxValues: [
                {name: 'New York', code: 'NY'},
                {name: 'Rome', code: 'RM'},
                {name: 'London', code: 'LDN'},
                {name: 'Istanbul', code: 'IST'},
                {name: 'Paris', code: 'PRS'}
            ],
            listboxValue: null,
            dropdownValues: [
                {name: 'New York', code: 'NY'},
                {name: 'Rome', code: 'RM'},
                {name: 'London', code: 'LDN'},
                {name: 'Istanbul', code: 'IST'},
                {name: 'Paris', code: 'PRS'}
            ],
            dropdownValue: null,
            multiselectValue: null,
            multiselectValues: [
                {name: 'Australia', code: 'AU'},
                {name: 'Brazil', code: 'BR'},
                {name: 'China', code: 'CN'},
                {name: 'Egypt', code: 'EG'},
                {name: 'France', code: 'FR'},
                {name: 'Germany', code: 'DE'},
                {name: 'India', code: 'IN'},
                {name: 'Japan', code: 'JP'},
                {name: 'Spain', code: 'ES'},
                {name: 'United States', code: 'US'}
            ],
            toggleValue: false,
            selectButtonValues1: [
                {name: 'Option 1', code: 'O1'},
                {name: 'Option 2', code: 'O2'},
                {name: 'Option 3', code: 'O3'},
            ],
            selectButtonValue1: null,
            selectButtonValues2: [
                {name: 'Option 1', code: 'O1'},
                {name: 'Option 2', code: 'O2'},
                {name: 'Option 3', code: 'O3'},
            ],
            selectButtonValue2: null,
			tieredMenuItems: [
				{
					label:'Customers', icon:'pi pi-fw pi-table',
					items:[
                        {
                            label:'New', icon:'pi pi-fw pi-user-plus',
                            items:[
                                {label:'Customer', icon:'pi pi-fw pi-plus'},
                                {label:'Duplicate', icon:'pi pi-fw pi-copy'},
                            ]},
                            {label:'Edit', icon:'pi pi-fw pi-user-edit'}
					]
				},
				{
					label:'Orders',
					icon:'pi pi-fw pi-shopping-cart',
					items:[
                        {label:'View', icon:'pi pi-fw pi-list'},
                        {label:'Search', icon:'pi pi-fw pi-search'},
					]
				},
				{
					label:'Shipments',
					icon:'pi pi-fw pi-envelope',
					items:[
                        {label:'Tracker', icon:'pi pi-fw pi-compass'},
                        {label:'Map', icon:'pi pi-fw pi-map-marker'},
                        {label:'Manage',	icon:'pi pi-fw pi-pencil'}
					]
				},
				{
					label:'Profile', icon:'pi pi-fw pi-user',
					items:[
                        {label:'Settings', icon:'pi pi-fw pi-cog'},
                        {label:'Billing', icon:'pi pi-fw pi-file'}
					]
				},
				{
					separator:true
				},
				{
					label:'Quit', icon:'pi pi-fw pi-sign-out'
				}
			],
			menuitems: [
				{
					label:'Customers',
					items:[
                        {label:'New', icon:'pi pi-fw pi-plus',},
                        {label:'Edit', icon:'pi pi-fw pi-user-edit'}
					]
				},
				{
					label:'Orders',
					items:[
                        {label:'View', icon:'pi pi-fw pi-list'},
                        {label:'Search', icon:'pi pi-fw pi-search'},
					]
				}
			],
			contextMenuItems: [
				{label: 'Save', icon: 'pi pi-save'},
				{label:'Update', icon: 'pi pi-refresh'},
				{label: 'Delete', icon: 'pi pi-trash'},
				{separator: true},
				{label: 'Options', icon: 'pi pi-cog'},
            ],
            message: [],
            timelineEvents: [
                {status: 'Ordered', description: 'Recebimento da Ordem de Compra', date: '10/08/2021', time: '09:30', icon: 'icon-r-circle-success-filled', color: '#25A57D', image: ''},
                {status: 'Processing', description: 'Aprovação da ordem de compra pelo usuário Daniela Dias', date: '15/08/2021', time: '10:11', icon: 'icon-r-circle-success-filled', color: '#25A57D', image: ''},
                {status: 'Shipped', description: 'Novo acordo disponível', date: '17/08/2021', time: '10:25', icon: 'icon-r-circle-warning-filled', color: '#F7D103', image: ''},
                {status: 'Shipped', description: 'Nota fiscal enviada pelo usuário Marcelo Silva', date: '17/08/2021', time: '10:25', icon: 'icon-r-circle-success-filled', color: '#25A57D', image: ''},
                {status: 'Shipped', description: 'Nota fiscal recusada pelo usuário Daniela Dias', date: '17/08/2021', time: '10:25', icon: 'icon-r-circle-error-filled', color: '#D54545', image: ''},
                {status: 'Delivered', description: 'Nota fiscal disponível para antecipação', date: '20/08/2021', time: '11:42', icon: 'icon-r-circle-warning-filled', color: '#1469C4', image: ''},
                {status: 'Delivered', description: 'Nota fiscal antecipada', date: '20/08/2021', time: '11:42', icon: 'icon-r-circle-error-filled', color: '#25A57D', image: ''}
            ],
            items: [
                {label: 'Home',to: '/'},
                {label: 'Personal',to: '/personal'},
                {label: 'Seat',to: '/seat'},
                {label: 'Payment',to: '/payment'},
                {label: 'Confirmation',to: '/confirmation'}
            ],
            formObject: {},
            visibleLeft: false,
            visibleRight: false,
            visibleTop: false,
            visibleBottom: false,
            visibleFull: false,
        }
    },
    countryService: null,
    created() {
        this.countryService = new CountryService();
        this.customerService = new CustomerService();
        this.productService = new ProductService();
    },
    mounted() {
        this.countryService.getCountries().then(data => this.autoValue = data);
        this.customerService.getCustomersLarge().then(data => {
            this.customers = data;
            this.customers.forEach(customer => customer.date = new Date(customer.date));
        });
        this.productService.getProductsSmall().then(data => this.products = data);
    },
    methods: {
        toggleMenu(event) {
			this.$refs.menu.toggle(event);
        },
        onContextRightClick(event) {
            this.$refs.contextMenu.show(event);
        },
        searchCountry(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.autoFilteredValue = [...this.autoValue];
                }
                else {
                    this.autoFilteredValue = this.autoValue.filter((country) => {
                        return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        addMessage(severity) {
            this.message = [{severity: severity, content: 'Message Detail'}]
        },
        showToast(severity) {
            this.$toast.add({severity: severity, summary: 'Message Summary', detail:'Message Detail', life: 3000});
        },
        open() {
			this.display = true;
		},
		close() {
			this.display = false;
        },
        toggle(event) {
			this.$refs.op.toggle(event);
        },
        confirmWithPopup(event) {
            this.$confirm.require({
                target: event.currentTarget,
                group: 'popup',
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        },
        confirmWithDialog() {
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirm',
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-raised',
                rejectClass: 'p-button-outlined p-button-raised',
                accept: () => {
                    this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        },
        deletmWithDialog() {
            this.$confirm.require({
                group: 'dialog',
                header: 'Delete Confirmation',
                message: 'Do you want to delete this record?',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-raised p-button-danger',
                rejectClass: 'p-button-outlined p-button-raised p-button-danger',
                accept: () => {
                    this.$toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000});
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        },
        onProductSelect(event) {
            this.$refs.op.hide();
            this.$toast.add({severity:'info', summary: 'Product Selected', detail: event.data.name, life: 3000});
        },
        formatDate(value) {
            return value.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        },
        nextPage(event) {
            for (let field in event.formData) {
                this.formObject[field] = event.formData[field];
            }

            this.$router.push(this.items[event.pageIndex + 1].to);
        },
        prevPage(event) {
            this.$router.push(this.items[event.pageIndex - 1].to);
        },
        complete() {
            this.$toast.add({severity:'success', summary:'Order submitted', detail: 'Dear, ' + this.formObject.firstname + ' ' + this.formObject.lastname + ' your order completed.'});
        }
    },
    computed: {
        containerClass() {
            return ['p-grid', {
                    'p-input-filled': this.$appState.inputStyle === 'filled',
                }
            ];
        }
    }
}
</script>

<style scoped lang="scss">
.p-progressbar {
    height: 4px;
}
  
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-right: auto;
    }
}

::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>