<template>
  <div class="custom-single-file">
    <ul>
      <li class="item">
        <label>Arquivo</label>
        <div><span>NFSe 00000643 - UNIAO STAR LOCACOES EIRELI</span></div>
      </li>
      <li class="item">
        <label>Fornecedor</label>
        <div><span>ROIT CONSULTORIA E CONTABILIDADE</span></div>
      </li>
      <li class="item">
        <label>Estabelecimento</label>
        <div><span>ROIT CONSULTORIA E CONTABILIDADE</span></div>
      </li>
      <li class="item">
        <label>Classificação</label>
        <div><span>ROIT</span></div>
      </li>
      <li class="item">
        <label>Data de Emissão</label>
        <div><span>15/01/2019</span></div>
      </li>
      <li class="item">
        <label for="Calendar">Calendar</label>
        <Calendar :showIcon="true" :showButtonBar="true" v-model="calendarValue2" dateFormat="dd/mm/yy" class="p-inputtext-sm"></Calendar>
      </li>
      <li class="item p-text-center">
        <i class="icon-r-user"></i><br>
        <a href="#"><span class="font-size-12">Adicionar<br>Responsável</span></a>
      </li>
      <li class="item p-text-center">
        <i class="icon-r-blockchain"></i><br>
        <a href="#"><span class="font-size-12">Docs<br>Vinculados</span></a>
      </li>
      <li class="item p-text-center">
        <i class="icon-r-file-lines color-white"></i><br>
        <a href="#"><span class="font-size-12">Visualizar<br>Cenário</span></a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
      data() {
          return {
            calendarValue2: null,
          }
      }
  }
</script>

<script lang="ts">
</script>

<style lang="scss" scoped>
</style>
