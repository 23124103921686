<template>
    <AppTopBar />
	<div class="layout-wrapper">
        <ToggleButton v-model="ToggleButtonMenu" onIcon="pi pi-bars" offIcon="pi pi-bars" class="topbar-btn-collapse" @click='showMenu' />
        <aside :class="{'menu-shadow': !isCollapse && !controlCLoseOpen ? !isCollapse : '', 'aside-close':isCollapse}" @mouseenter="isCollapse = false" @mouseleave="controlCLoseOpen === false ? isCollapse = true : isCollapse = false">
            <MegaMenu :model="items" orientation="vertical"/>
        </aside>
        <main :class="{'collapse-open': controlCLoseOpen === true ? !isCollapse : '', 'collapse-close':isCollapse}">
			<router-view />
		</main>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar';

export default {
    data() {
        return {
            ToggleButtonMenu: false,
            isCollapse: true,
            controlCLoseOpen: false,
            items: [
                {
                    label: 'Indicadores', icon: 'icon-r-growth',
                },
                {
                    label: 'Esteira Mágica', icon: 'icon-r-flow-magic',
                },
                {
                    label: 'Conciliador Mágico', icon: 'icon-r-conciliator',                 
                },
                {
                    label: 'Pagamentos', icon: 'icon-r-credit',
                },
                {
                    label: 'Gestão de Entradas', icon: 'icon-r-file-upload-2',
                },
                {
                    label: 'Motor de Regras', icon: 'icon-r-settings',
                },
                {
                    label: 'Gerar Relatório', icon: 'icon-r-file-copy',
                },
                {
                    label: 'Certificados Digitais', icon: 'icon-r-file-upload-2',
                },
                {
                    label: 'Documentos Processados', icon: 'icon-r-file-check',
                },
                {
                    label: 'Gestão de Fornecedores', icon: 'icon-r-team-work-2',
                },
            ]
        }
    },
    methods: {
        showMenu(){
            this.isCollapse = !this.isCollapse;
            this.controlCLoseOpen = !this.controlCLoseOpen;
        }
        ,
        hideMenu(){

        }
    },
	components: {
		'AppTopBar': AppTopBar,
	}
}
</script>

<style lang="scss">

</style>
