<template>
    <header>
        <div id="topbar">
            <div id="topbar-logo">
                <img src="../src/assets/images/logo-bank.svg" alt="" class="logo-client">
            </div>
            <div id="topbar-options">
                <div id="search">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText type="text" v-model="valueSearch" placeholder="Search" />
                    </span>
                </div>
                <div id="user">
                    <Button type="button" @click="user_overlay" aria:haspopup="true" aria-controls="user_overlay">
                        <Avatar label="RS" size="small" shape="circle" />
                    </Button>
                    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false" class="user_overlay" style="width: 320px" :breakpoints="{'960px': '75vw'}">
                        <div class="p-d-flex p-jc-center">
                            <Avatar label="RS" size="large" shape="circle" class="avatar" />
                        </div>
                        <div class="p-text-center">
                            <p class="p-text-bold p-mb-1 p-mt-3">Daniela Dias</p>
                            <p class="p-mb-0 font-size-14 color-black-54">MADERO</p>
                            <p class="font-size-14 color-black-54 p-mb-3">dani.dias@restaurantemadero.com.br</p>
                            <Button label="Gerenciar Perfil" class="p-button-outlined p-button-secondary p-button-sm" />
                        </div>
                        <Divider />
                        <Menu :model="navUser" />
                        <Divider />
                        <div class="p-d-flex p-jc-center">
                            <Button label="Sair" class="p-button-outlined" />
                        </div>
                    </OverlayPanel>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            valueSearch: null,
            navUser: [
                {
                    items: [{
                        label: 'Configurações',
                        //icon: 'pi pi-external-link',
                        url: 'https://vuejs.org/'
                    },
                    {
                        label: 'Alterar Senha',
                        //icon: 'pi pi-upload',
                        command: () => {
                            window.location.hash = "/fileupload"
                        }
                    }
                ]}
            ]
        }
    },
    methods: {
        user_overlay(event) {
            this.$refs.op.toggle(event);
        },
    },
    computed:{
        main() {
            return this.$route.path === '/';
        }
    }
}
</script>
